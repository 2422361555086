<template>
  <div class="attachment">
    <div class="status">
      <v-progress-circular
        v-if="isFileUploading"
        size="20"
        width="2"
        indeterminate
        color="loaderColor"
      />
    </div>
    <div class="name">
      {{ attachment.attachFullName }}
    </div>
    <div :class="`options${readonly ? ' single-button' : ''}`">
      <div class="buttons" v-if="!isFileUploading">
        <v-btn icon text @click="onDownloadFile">
          <IconBox color="iconButton">stem-import</IconBox>
        </v-btn>
        <v-btn v-if="!readonly" icon text @click="onDeleteItem">
          <IconBox color="iconButton">stem-delete</IconBox>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { AttachmentStatus } from "@/utils/FileHelper";

export default {
  name: "AttachmentField",
  props: {
    attachment: { type: Object, default: () => {} },
    readonly: { type: Boolean, default: false },
  },
  computed: {
    isFileUploading() {
      return (
        !this.attachment.attachStatus ||
        this.attachment.attachStatus === AttachmentStatus.Uploading
      );
    },
  },
  data() {
    return {};
  },
  methods: {
    onDeleteItem() {
      this.$emit("deleteItem", this.attachment.attachId);
    },
    onDownloadFile() {
      this.$emit("downloadItem", this.attachment.attachId);
    },
  },
};
</script>

<style scoped lang="scss">
.attachment {
  display: flex;
  flex-direction: row;
  min-height: 40px;
  align-items: center;
  background-color: white;

  .status {
    margin-left: 10px;
    flex: 0 0 30px;
  }

  .name {
    color: $text-color;
    margin-right: 20px;
  }

  .options {
    margin-left: auto;
    flex: 0 0 80px;

    &.single-button {
      flex: 0 0 40px;
    }

    .buttons {
      display: none;
    }
  }

  &:hover {
    background-color: $content-section-background;

    .buttons {
      display: block;
    }
  }
}
</style>

import * as CplStates from "../models/cplStates";

export const complaintStateColors = {
  // TODO change colors
  [CplStates.NEW]: "#F7E008",
  [CplStates.INCOMPLETED]: "#F3BB25",
  [CplStates.REALIZATION]: "#77CE36",
  [CplStates.CLOSED]: "#076FF8",
  [CplStates.SUSPENDED]: "#6E6C68",
  [CplStates.CANCELED]: "#BAB7B3",
};

export const getComplaintStateColor = (stateId) => {
  if (!stateId) return "";

  return complaintStateColors[stateId];
};

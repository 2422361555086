<template>
  <v-badge color="badgeBackground" overlap>
    <template #badge>
      <span class="badgeText--text">{{ count }}</span>
    </template>
    <IconButton
      :tooltip="$t('common.attachments')"
      v-bind="$attrs"
      v-on="$listeners"
    >
      stem-attachment
    </IconButton>
  </v-badge>
</template>

<script>
import IconButton from "@/components/Shared/IconButton";

export default {
  name: "AttachmentBadge",
  components: { IconButton },
  props: {
    count: {
      type: Number,
      default: 0,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep.v-badge__badge {
  top: -3px;
  right: -3px;
}
</style>

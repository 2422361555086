<template>
  <div>
    <ModalDialog
      v-model="isModalShow"
      :maxWidth="'900px'"
      :header="''"
      :confirmText="$t('common.save')"
      :cancelText="$t('common.reject')"
      :isLoading="isUploadingFile"
      :editMode="!readonly"
      :persistent="true"
      @close="onCancel"
      @cancel="onCancel"
      @confirm="onConfirm"
    >
      <template #activator="{ on }">
        <AttachmentBadge v-on="on" :count="attachmentsCount" />
      </template>

      <div slot="content">
        <div class="content-header">
          <div class="header-title">
            {{ $t("complaints.attachments.attachmentsTitle").toUpperCase() }}
          </div>
          <div class="header-button">
            <input
              type="file"
              ref="inputFile"
              :accept="
                getAttachmentsRestriction.attachmentFileExtensions
                  .map((item) => `.${item}`)
                  .join()
              "
              @change="onSelectFileChange"
            />
            <IconButton
              v-if="!readonly"
              :disabled="attachments.length >= 6"
              :tooltip="$t('complaints.attachments.addAttachment')"
              @click.stop="openFileWindow"
            >
              stem-attachmentPlus
            </IconButton>
          </div>
        </div>

        <div class="content-details">
          <div class="attachments-list">
            <AttachmentField
              v-for="item in attachments"
              :key="item.attachId"
              :attachment="item"
              :readonly="readonly"
              @deleteItem="onDeleteButtonClick"
              @downloadItem="onDownloadItem"
            />
          </div>
        </div>

        <ModalWindow ref="confirmModal" />
      </div>
    </ModalDialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { AttachmentStatus } from "@/utils/FileHelper";
import { downloadFile } from "@/utils/FileManager.js";

import { EmitError } from "@/eventBus.js";
import { readFileAsDataURL } from "@/utils/FileHelper";
import ComplaintsService from "@/services/ComplaintsService";

import AttachmentField from "./AttachmentField";
import AttachmentBadge from "./AttachmentBadge";
import ModalDialog from "@/components/Shared/ModalDialog";
import IconButton from "@/components/Shared/IconButton";
import ModalWindow from "@/components/Shared/ModalWindow";

export default {
  name: "AttachmentModal",
  components: {
    AttachmentBadge,
    ModalDialog,
    IconButton,
    AttachmentField,
    ModalWindow,
  },
  props: {
    attachmentsCount: { type: Number },
    complaintId: { type: String, default: undefined },
    subcategoryId: { type: Number },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      addedAttachmentIds: [], // id załączników które zostały wcześniej dodane
      currentAttachmentIds: [], // id wszystkich załączników
      attachmentIdsToDelete: [], // Id załączników do usunięcia, po kliknięciu w przycisk OK
      attachments: [],
      isModalShow: false,
      isTimerEnable: false,
      timerCountdown: null,
      isUploadingFile: false,
      webController: null,
    };
  },
  computed: {
    ...mapGetters("settings", ["getAttachmentsRestriction"]),
  },
  methods: {
    onCancel() {
      if (this.readonly) {
        this.isModalShow = false;
        return;
      }

      const deleteUnsavedAttachmentIds = this.currentAttachmentIds.filter(
        (item) => !this.addedAttachmentIds.includes(item)
      );

      if (deleteUnsavedAttachmentIds.length > 0) {
        ComplaintsService.deleteAttachments(deleteUnsavedAttachmentIds);
      }

      this.webController.abort();

      this.isUploadingFile = false;
      this.isModalShow = false;
      this.attachments = [];
      // this.currentAttachmentIds = [];
      this.attachmentIdsToDelete = [];
      this.$emit("onChange", this.currentAttachmentIds);
    },
    onConfirm() {
      if (this.readonly) {
        this.isModalShow = false;
        return;
      }

      if (this.attachmentIdsToDelete.length > 0) {
        ComplaintsService.deleteAttachments(this.attachmentIdsToDelete)
          .then(() => {
            this.currentAttachmentIds = this.currentAttachmentIds.filter(
              (item) => !this.attachmentIdsToDelete.includes(item)
            );

            this.closeAndSaveChanges();
          })
          .catch(() => {
            EmitError(this.$t("complaints.attachments.deleteError"));
          });
      } else {
        this.closeAndSaveChanges();
      }
    },
    closeAndSaveChanges() {
      if (this.complaintId) {
        this.isUploadingFile = true;
        ComplaintsService.UpdateComplaintAttachments(
          this.complaintId,
          this.currentAttachmentIds
        )
          .then(() => {
            this.$emit("onChange", this.currentAttachmentIds);
            this.isModalShow = false;
          })
          .catch(() => {
            EmitError(this.$t("complaints.attachments.addError"));
          })
          .finally(() => (this.isUploadingFile = false));
      } else {
        this.$emit("onChange", this.currentAttachmentIds);
        this.isModalShow = false;
      }
    },
    openFileWindow() {
      this.$refs.inputFile.click();
    },
    onSelectFileChange(event) {
      const selectedFile = event.currentTarget.files
        ? event.currentTarget.files[0]
        : null;

      if (selectedFile) {
        if (selectedFile.name.split(".").length === 1) {
          EmitError(this.$t("complaints.attachments.wrongFileType"));
          return;
        }

        const fileExtension = selectedFile.name.split(".").pop();

        if (
          selectedFile.size > this.getAttachmentsRestriction.attachmentSizeLimit
        ) {
          EmitError(this.$t("complaints.attachments.fileTooBig"));
        } else if (selectedFile.size === 0) {
          EmitError(this.$t("complaints.attachments.emptyFile"));
        } else if (
          this.attachments.some(
            (attachment) => attachment.attachFullName === selectedFile.name
          )
        ) {
          EmitError(this.$t("complaints.attachments.duplicatedName"));
        } else if (
          !this.getAttachmentsRestriction.attachmentFileExtensions.includes(
            fileExtension
          )
        ) {
          const availableExtensions =
            this.getAttachmentsRestriction.attachmentFileExtensions
              .map((item) => `.${item}`)
              .join(" ,");

          EmitError(
            `${this.$t(
              "complaints.attachments.wrongExtension"
            )}: ${availableExtensions}`
          );
        } else {
          this.$refs.inputFile.value = "";
          this.sendAttachment(selectedFile);
        }
      }
    },
    sendAttachment(file) {
      readFileAsDataURL(file).then((convertedFile) => {
        const attachment = {
          attachFullName: file.name,
          attachExtension: file.name.split(".").pop(),
          attachContentType: file.type,
          attachSize: file.size,
          attachBlob: convertedFile,
          subcategoryId: this.subcategoryId,
        };
        this.attachments.push({ ...attachment });
        this.isUploadingFile = true;

        ComplaintsService.addAttachment(attachment, this.webController.signal)
          .then((attachmentId) => {
            this.currentAttachmentIds = [
              ...this.currentAttachmentIds,
              attachmentId,
            ];

            this.checkAttachmentsStatus();
          })
          .catch(() => {
            this.attachments = this.attachments.filter(
              (item) => item.attachFullName !== file.name
            );

            if (
              this.attachments.every((item) => {
                return (
                  item.attachStatus &&
                  item.attachStatus !== AttachmentStatus.Uploading
                );
              })
            ) {
              this.isUploadingFile = false;
            }

            EmitError(this.$t("complaints.attachments.addError"));
          });
      });
    },
    checkAttachmentsStatus() {
      ComplaintsService.checkAttachmentStatus(
        this.currentAttachmentIds,
        this.webController.signal
      )
        .then((response) => {
          const newAttachments = [];

          this.attachments.forEach((attachment) => {
            const responseAttachment = response.find(
              (item) => item.attachFullName === attachment.attachFullName
            );

            if (responseAttachment) {
              newAttachments.push(responseAttachment);
            } else {
              newAttachments.push(attachment);
            }
          });

          this.attachments = newAttachments;

          if (
            response.some(
              (item) => item.attachStatus === AttachmentStatus.Uploading
            )
          ) {
            this.startTimer();
          } else {
            this.stopTimer();
            this.isUploadingFile = false;
          }
        })
        .catch(() => {
          this.isUploadingFile = false;
        });
    },
    startTimer() {
      this.isTimerEnable = true;
      this.timerCountdown = 3;
    },
    stopTimer() {
      this.isTimerEnable = false;
    },
    onDeleteButtonClick(attachmentId) {
      this.$refs.confirmModal
        .open(
          this.$t("global.confirm").toUpperCase(),
          this.$t("global.deleteItemConfirm")
        )
        .then((result) => {
          if (result) {
            this.onDeleteItem(attachmentId);
          }
        });
    },
    onDeleteItem(attachmentId) {
      this.attachmentIdsToDelete.push(attachmentId);

      this.attachments = this.attachments.filter((item) => {
        return item.attachId !== attachmentId;
      });
    },
    onDownloadItem(attachmentId) {
      ComplaintsService.getAttachmentFile(attachmentId).then((result) => {
        const attachment = this.attachments.find((item) => {
          return item.attachId === attachmentId;
        });

        const fileName = attachment
          ? attachment.attachFullName.split(".")[0]
          : "Załącznik";

        downloadFile(
          result.file,
          fileName,
          result.type,
          attachment.attachExtension
        );
      });
    },
  },
  watch: {
    isModalShow(isShown) {
      if (isShown) {
        this.webController = new AbortController();

        if (this.complaintId) {
          ComplaintsService.getAttachments(this.complaintId).then(
            (response) => {
              const attachIds = response.map((item) => item.attachId);

              this.attachments = response;
              this.addedAttachmentIds = attachIds;
              this.currentAttachmentIds = attachIds;
            }
          );
        }
      }
    },
    timerCountdown(newValue) {
      if (this.isTimerEnable) {
        if (newValue === 0) {
          this.checkAttachmentsStatus();
        } else {
          setTimeout(() => {
            this.timerCountdown -= 1;
          }, 1000);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;

  .header-title {
    color: $text-title;
    font-size: 16px;
    width: 80%;
  }

  .header-button {
    margin-left: auto;

    input {
      display: none;
    }
  }
}
</style>

export const readFileAsDataURL = (file) => {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
  });
};

export const AttachmentStatus = {
  Uploading: "UPLOADING",
  Pending: "PENDING",
  Added: "ADDED",
};

<template>
  <IconBox
    :tooltip="name ? `${$t('complaints.priority')} ${name}` : undefined"
    :styles="{ ...arrowStyle, ...arrowBackgroundStyle }"
    v-bind="$attrs"
    >{{ arrowNameConfig.arrowName }}</IconBox
  >
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    arrowName: String,
    name: String,
    setBackground: { type: Boolean, default: false },
  },
  computed: {
    arrowNameConfig() {
      switch (this.id) {
        case 100000:
          return {
            arrowName: "stem-arrows-two-down-filled",
          };
        case 10000:
          return {
            arrowName: "stem-arrows-two-down",
          };
        case 1000:
        case 5000:
          return {
            arrowName: "stem-arrows-one-down",
          };
        case 100:
        case 500:
          return {
            arrowName: "stem-arrows-one-up",
          };
        case 10:
        case 50:
          return {
            arrowName: "stem-arrows-two-up",
          };
        case 1:
        case 5:
          return {
            arrowName: "stem-arrows-two-up-filled",
          };
      }
      return {};
    },
    arrowStyle() {
      switch (this.id) {
        case 100000:
          return {
            color: this.$vuetify.theme.themes.light.priority_off,
          };
        case 10000:
          return {
            color: this.$vuetify.theme.themes.light.priority_standby,
          };
        case 1000:
        case 5000:
          return {
            color: this.$vuetify.theme.themes.light.priority_normal, //niski
          };
        case 100:
        case 500:
          return {
            color: this.$vuetify.theme.themes.light.priority_caution, //podstawowy
          };
        case 10:
        case 50:
          return {
            color: this.$vuetify.theme.themes.light.priority_serious, //ok
          };
        case 1:
        case 5:
          return {
            color: this.$vuetify.theme.themes.light.priority_critical, //ok
          };
      }
      return {};
    },
    arrowBackgroundStyle() {
      if (this.setBackground) {
        return {
          backgroundColor: "white",
          borderRadius: "50%",
          height: "22px",
          width: "22px",
        };
      }
      return {};
    },
  },
};
</script>

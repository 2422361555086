<template>
  <v-btn icon text normal v-bind="$attrs" v-on="$listeners">
    <IconBox :styles="styles" :tooltip="tooltip" color="iconButton">
      <slot></slot>
    </IconBox>
  </v-btn>
</template>

<script>
export default {
  name: "IconButton",
  props: {
    icon: {
      type: String,
      required: false,
    },
    tooltip: {
      type: String,
    },
    styles: {
      type: [Object],
    },
  },
};
</script>
